import { ReactComponent as LogoIcon } from "assets/logo.svg";
import { ReactComponent as LeftArrowIcon } from "assets/icons/leftArrow.svg";
import { ReactComponent as AvatarIcon } from "assets/profile.svg";
import { ReactComponent as AuditIcon } from "assets/sidebar/audit.svg";
import { ReactComponent as ServicesIcon } from "assets/sidebar/consumption.svg";
import { ReactComponent as DocIcon } from "assets/sidebar/doc.svg";
import { ReactComponent as FaqIcon } from "assets/sidebar/faq.svg";
import { ReactComponent as OrgsIcon } from "assets/sidebar/orgs.svg";
import { ReactComponent as SettingsIcon } from "assets/sidebar/users.svg";
import { ReactComponent as ExportIcon } from "assets/sidebar/export.svg";
import { FileText, FileDown } from "lucide-react";
import Button from "components/button";
import Link from "components/link";
import NavDropdown from "components/navDropdown";
import NavSector from "components/navSector";
import SideBarLoader from "components/sidebarLoader";
import { Divisor } from "global.styles";
import { useAuth } from "providers/auth";
import { useUser } from "providers/user";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { getFirstAndLastWord, ls } from "utils";
import NavItem from "../navItem";
import { Nav, NavButton, SideBarConainer } from "./styles";
import { NavLink } from "react-router-dom";
import Avatar from "components/avatar";
const menuKey = "isCollapsed";

const SideBar = () => {
  const [isCollapsed, setIsCollapsed] = useState(
    ls.getItem(menuKey) === "true"
  );
  const { t } = useTranslation("components");
  const { signOut } = useAuth();
  const [viewItem, setViewItem] = useState(false);
  const { currentUser, initLoading } = useUser();

  const profile = useMemo(
    () => [
      {
        label: t("sideBar.profile"),
        path: "profile"
      }
    ],
    [t]
  );
  const navItems = [
    {
      icon: <OrgsIcon />,
      name: t("sideBar.organizations"),
      path: "/organizations"
    },
    {
      icon: <SettingsIcon />,
      name: t("sideBar.users"),
      path: "/users"
    },
    {
      icon: <ServicesIcon />,
      name: t("sideBar.finance"),
      path: "/finance"
    },
    {
      icon: <FileText size={18} color="#979AA5" />,
      name: t("sideBar.plans"),
      path: "/services"
    }
  ];

  const navItemsPrivate = [
    {
      icon: <FileDown size={18} color="#979AA5" />,
      name: t("sideBar.import"),
      path: "/invoice-import"
    },
    {
      icon: <ExportIcon />,
      name: t("sideBar.export"),
      path: "/invoice-export"
    },
    {
      icon: <AuditIcon />,
      name: t("sideBar.auditLogs"),
      path: "/audit-logs"
    }
  ];

  const infoNavItems = [
    {
      icon: <DocIcon />,
      name: t("sideBar.documentation"),
      path: "/doc"
    },
    {
      icon: <FaqIcon />,
      name: t("sideBar.faq"),
      path: "/faq"
    }
  ];

  const newNameUser = getFirstAndLastWord(currentUser?.name);

  useEffect(() => {
    const userType = currentUser?.claim?.type;
    const userClaim = currentUser?.claim?.title;
    const CodeHabilit = ["root", "admin"];
    switch (userType) {
      case "ligo":
        if (CodeHabilit.includes(userClaim as string)) {
          setViewItem(true);
          return;
        }
        setViewItem(false);
        break;
      case "client":
        setViewItem(false);
        break;
      default:
        return setViewItem(false);
    }
  }, [currentUser]);

  const handleToggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
    ls.setItem(menuKey, `${!isCollapsed}`);
  }, [isCollapsed]);

  return (
    <SideBarConainer isCollapsed={isCollapsed}>
      <NavButton
        isCollapsed={isCollapsed}
        icon={<LeftArrowIcon />}
        onClick={handleToggleCollapse}
      />
      <NavLink to="/">
        {isCollapsed ? <LogoIcon width="100%" /> : <LogoIcon width="100%" />}
      </NavLink>
      <Nav>
        {initLoading ? (
          <SideBarLoader />
        ) : (
          <>
            <NavDropdown
              item={profile}
              justIcon={isCollapsed}
              label={t("sideBar.user")}
              text={newNameUser || ""}
              icon={
                <Avatar
                  small={true}
                  src={currentUser?.profilePhoto}
                  icon={<AvatarIcon />}
                />
              }
            />
            <NavSector justIcon={isCollapsed} />
          </>
        )}
        <Divisor />
        {navItems?.map((item, index) => (
          <NavLink key={index} to={item.path}>
            <NavItem icon={item.icon} name={item.name} justIcon={isCollapsed} />
          </NavLink>
        ))}
        {viewItem &&
          navItemsPrivate?.map((item, index) => (
            <NavLink key={index} to={item.path}>
              <NavItem
                icon={item.icon}
                name={item.name}
                justIcon={isCollapsed}
              />
            </NavLink>
          ))}
        <Divisor />
        {infoNavItems?.map((item, index) => (
          <Link key={index} to={item.path}>
            <NavItem icon={item.icon} name={item.name} justIcon={isCollapsed} />
          </Link>
        ))}
        <Button fullWidth outline onClick={() => signOut()}>
          {t("exit")}
        </Button>
      </Nav>
    </SideBarConainer>
  );
};

export default SideBar;
